//Global Contact

var label = jQuery('.global-contact-label');
var footer = jQuery('footer');
var closeButton = jQuery('.btn-menu-contact');


function setPosition() {
  const footerWidth = footer.offsetWidth;
  const windowWidth = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0) - 5;

  const labelOffset = ((windowWidth - footerWidth) / 2) + 2.5;


  label.css("right", labelOffset + 'px');
  label.css("display", "flex");
}

function setEvents() {

  label.click(function () {


    if (event.target.classList.contains('icon-holder') || event.target.closest('.icon-holder')) {
      label.addClass('expanded');
      jQuery(".expand-contacts").addClass('expanded');
    }
    if (closeButton.hasClass('wantclose')) {
      closeButton.removeClass('wantclose');
      label.removeClass('expanded');
      jQuery(".expand-contacts").removeClass('expanded');
    }


  });


  closeButton.click(function () {
    closeButton.addClass('wantclose');
    label.removeClass('expanded');
    jQuery(".expand-contacts").removeClass('expanded');

  });

  jQuery(window).scroll(function () {

    this.dockLabel();
  });
}


function dockLabel() {
  const scrollMaxY = window.scrollMaxY || (document.documentElement.scrollHeight - document.documentElement.clientHeight);

  const mobileQuery = window.matchMedia('(min-width: 768px)');
  const tabletQuery = window.matchMedia('(min-width: 1024px)');


  if ((window.scrollY > 50 && window.scrollY < scrollMaxY - 200) || !mobileQuery.matches) {

    if (window.scrollY < scrollMaxY - 200 && tabletQuery.matches) {

      label.css("bottom", "155px");
    }

    label.addClass('docked');
  } else {
    if (window.scrollY <= 50) {

      label.css("bottom", "");
    }

    label.removeClass('docked');
  }
}

function setup() {
  this.setPosition();
  this.setEvents();
  this.dockLabel();
}

setup();

 